<template>
  <EntityList
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    :extra-actions="extraActions"
    authority="General_Announcement"
    single-edit
    desc-sort
  />
</template>

<script>
import { getUserRights } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../announcementStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      categoryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'announcements',
        endpoint: 'announcements',
        route: 'announcements',
        title: {
          single: this.$i18n.t('Announcement'),
          plural: this.$i18n.t('Announcements'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'creationDate', label: this.$t('Creation Date'), sortable: true },
        { key: 'categories', label: this.$t('Categories'), sortable: false },
        { key: 'enabled', label: this.$t('enabled') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'enabled', type: 'boolean' },
        { key: 'creationDate', type: 'datetime' },
        { key: 'categories', type: 'list' },
      ]
    },

    extraActions() {
      const rights = getUserRights()

      return [
        {
          endpoint: 'eshop/announcement-publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          // @TODO this needs to be added in the back
          renderCondition: {
            key: 'isOnline',
            value: false,
          },
          authorityValidation: rights.includes('General_Announcement_Publish_Edit'),
        },
      ]
    },

    filters() {
      return [
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '1',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '0',
          maxDate: new Date(),
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('Enabled'),
          value: null,
        },
        {
          name: 'category',
          label: this.$t('category'),
          options: this.categoryOptions,
          value: null,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchPostCategories')
      .then(response => {
        this.categoryOptions = response.data
      })
  },
}
</script>
